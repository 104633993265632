import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vue from '../main';

import { loginUrl } from '@routes';

Vue.use(VueAxios, axios);

Vue.axios.interceptors.request.use(config => {
    if (config.url !== loginUrl()) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }

    return config;
}, error => Promise.reject(error));

Vue.axios.interceptors.response.use(response => response, error => {
    if (error.response.status === 401 && error.response.config.url !== loginUrl()) {
        localStorage.removeItem('token');

        if (vue.$route.name) {
            vue.$router.replace({ name: 'Login', params: vue.$route.params });
        }
    } else if (error.response.status === 400 && error.response.config.url === loginUrl()) {
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});