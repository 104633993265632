import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('token');

            if (token) {
                localStorage.removeItem('token');
            }

            next();
        }
    },
    {
        path: '',
        component: () => import('../views/Base'),
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('token');

            if (token) {
                next();
            } else {
                next({ name: 'Login', replace: true });
            }
        },
        children: [
            {
                path: '/',
                name: 'Home',
                component: () => import('../views/Home'),
                meta: {
                    module: 'home'
                }
            },
            {
                path: 'inventory',
                name: 'InventoryIndex',
                component: () => import('../views/Inventory/Index'),
                meta: {
                    module: 'inventory'
                }
            },
            {
                path: 'inventory-data',
                name: 'InventoryDataIndex',
                component: () => import('../views/InventoryData/Index'),
                meta: {
                    module: 'inventory-data'
                }
            },
            {
                path: 'inventory-movement',
                name: 'InventoryMovementIndex',
                component: () => import('../views/InventoryMovement/Index'),
                meta: {
                    module: 'movements'
                }
            },
            {
                path: 'repair',
                name: 'RepairIndex',
                component: () => import('../views/Repair/Index'),
                meta: {
                    module: 'repair'
                }
            },
            {
                path: 'repair/:id/show',
                name: 'RepairShow',
                component: () => import('../views/Repair/Show'),
                meta: {
                    module: 'repair'
                }
            },

            // Item
            {
                path: 'item',
                name: 'ItemIndex',
                component: () => import('../views/Item/Index'),
                meta: {
                    module: 'item'
                }
            },

            // Item
            {
                path: 'user',
                name: 'UserIndex',
                component: () => import('../views/User/Index'),
                meta: {
                    module: 'user'
                }
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name === 'Login') {
        next();
    } else {
        // if (Permission.check(to.meta.rule) || !to.meta.rule) {
        next();
        // } else {
        //     next({ name: 'Login', replace: true });
        // }
    }
});

export default router;
