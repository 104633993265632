const baseUrl = process.env.VUE_APP_BASE_URL;

//Modules
const api = '/api';
const login = '/login_check';
const item = '/item';
const unit = '/unit';
const warehouse = '/warehouse';
const inventory = '/inventory';
const inventoryData = '/inventory-data';
const site = '/site';
const repair = '/repair';
const repairData = '/repair-data';
const home = '/home';
const user = '/user';
const serviceCenter = '/service-center';
const inventoryMovement = '/inventory-movement';
const movement = '/movement';
const file = '/file';

//Actions
const list = '/list';
const save = '/save';
const data = '/data';
const filterData = '/filter-data';
const upload = '/upload';
const deleteAction = '/delete';
const download = '/download';

module.exports = {
    loginUrl: () => `${baseUrl}${api}${login}`,

    // Item
    itemListUrl: () => `${baseUrl}${api}${item}${list}`,
    itemSaveUrl: () => `${baseUrl}${api}${item}${save}`,
    itemUrl: id => `${baseUrl}${api}${item}/${id}`,
    itemEnableUrl: id => `${baseUrl}${api}${item}/${id}`,
    itemDataUrl: () => `${baseUrl}${api}${item}${data}`,

    //Unit
    unitDataUrl: () => `${baseUrl}${api}${unit}${data}`,

    //Warehouse
    warehouseDataUrl: () => `${baseUrl}${api}${warehouse}${data}`,

    //Inventory
    inventoryListUrl: () => `${baseUrl}${api}${inventory}${list}`,
    inventorySaveUrl: () => `${baseUrl}${api}${inventory}${save}`,
    inventoryUrl: id => `${baseUrl}${api}${inventory}/${id}`,
    inventoryDataUrl: () => `${baseUrl}${api}${inventory}${data}`,

    //Site
    siteDataUrl: () => `${baseUrl}${api}${site}${data}`,

    //Repair
    repairListUrl: () => `${baseUrl}${api}${repair}${list}`,
    repairUrl: id => `${baseUrl}${api}${repair}/${id}`,
    repairDataUrl: () => `${baseUrl}${api}${repair}${data}`,

    //Home
    homeUrl: () => `${baseUrl}${api}${home}`,

    // User
    userListUrl: () => `${baseUrl}${api}${user}${list}`,
    userSaveUrl: () => `${baseUrl}${api}${user}${save}`,
    userUrl: id => `${baseUrl}${api}${user}/${id}`,
    userEnableUrl: id => `${baseUrl}${api}${user}/${id}`,
    userDataUrl: () => `${baseUrl}${api}${user}${data}`,

    //Service Center
    serviceCenterDataUrl: () => `${baseUrl}${api}${serviceCenter}${data}`,

    //Inventory Movement
    inventoryMovementListUrl: () => `${baseUrl}${api}${inventoryMovement}${list}`,

    //Inventory Data
    inventoryDataListUrl: () => `${baseUrl}${api}${inventoryData}${list}`,
    inventoryDataFilterDataUrl: () => `${baseUrl}${api}${inventoryData}${filterData}`,
    inventoryDataMovementSaveUrl: () => `${baseUrl}${api}${inventoryData}${movement}${save}`,
    inventoryDataSaveUrl: () => `${baseUrl}${api}${inventoryData}${save}`,

    //Repair Data
    repairDataSaveUrl: () => `${baseUrl}${api}${repairData}${save}`,
    repairDataGetUrl: id => `${baseUrl}${api}${repairData}/${id}`,

    //File
    fileUploadUrl: () => `${baseUrl}${api}${file}${upload}`,
    fileDeleteUrl: id => `${baseUrl}${api}${file}/${id}${deleteAction}`,
    fileDownloadUrl: id => `${baseUrl}${api}${file}/${id}${download}`
};