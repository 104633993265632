class Permission {
    static check(roleName) {

        let rolesString, roles = [];

        if (localStorage.getItem('roles')) {
            rolesString = localStorage.getItem('roles');
            roles = JSON.parse(rolesString);
        }

        return roles.includes(roleName);
    }
}

export default Permission;